gallery = {
  settings: {
    gallery: $('.js-gallery')
  },
  init: function(){
    gallery.initMasonry();
    gallery.initMagnific();
  },
  initMasonry: function(){
    if(gallery.settings.gallery.length){
      gallery.settings.gallery.imagesLoaded().progress(function(){
        gallery.settings.gallery.masonry();
      });
    }
  },
  initMagnific: function(){
    if(gallery.settings.gallery.length){
      gallery.settings.gallery.magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
          enabled: true
        }
      });
    }
  }
}