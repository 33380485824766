navigation = {
  settings: {
    button: $('.js-nav-toggle'),
    body: $('.js-body')
  },
  init: function(){
    navigation.settings.button.click(function(e){
      e.preventDefault();
      navigation.settings.body.toggleClass('js-nav-is-active');
    })
  }
}