slideshow = {
  settings: {
    slideshow: $('.js-slideshow'),
    slide: '.js-slide',
    controls: '.js-slide-controls'
  },
  init: function(){
    console.log("it works");
    if(slideshow.settings.slideshow.length){
      slideshow.settings.slideshow.flexslider({
        namespace: 'c-slideshow-',
        selector: slideshow.settings.slide,
        controlsContainer: slideshow.settings.controls,
        controlNav: true,
        directionNav: false,
      });
    }
  }
}